import { on_ready, is_elementor_edit, hover_intent, queue_tasks } from './js/utils';
import retina from './vendors/retina/retina';

import './main.scss';

import 'components/forms/forms';
import 'components/header/header';
import 'components/carousel/carousel';
import 'components/accordions/accordions';
import 'components/toggle_height';
import 'components/footer/sticky_footer_cta';

on_ready(()=>{

    if( is_elementor_edit() ) return;

    queue_tasks([
        dynamic_imports,
        // init_hover_intent,
        init_retina,
        cta_fw_key_options,
    ]);

    setTimeout(()=>{
        check_elements_scrollbar();
    }, 200);
});

function dynamic_imports(){
    // slide menu
    if( document.querySelector('.ff_slide_menu') ) {
        import('components/slide_menu/slide_menu');
    }
    // search popup
    if( document.querySelector('.search_popup') ) {
        import('components/search_popup/search_popup');
    }
}

// function init_hover_intent(){
//     document.querySelectorAll('.hover_intent').forEach(el=>{
//         hover_intent(el);
//     });
// }

// add class to elements with scrollbar - for extra padding styling
function check_elements_scrollbar(){
    document.querySelectorAll('.check_scrollbar').forEach(element=>{
        if( element.scrollHeight > element.clientHeight ) {
            element.classList.add('with_scrollbar');
        }
    })
}

function init_retina(){
    
    if( typeof retina === 'undefined' ) return;
    if( window.devicePixelRatio < 2) return;

    let items = document.querySelectorAll('.retina');
    if( !items ) return;
    
    items.forEach(item=>{
        if( item.nodeName == 'IMG' ) {
            item.dataset.rjs = 2;
        } else {
            // add to children
            item.querySelectorAll('img').forEach(img=>{
                img.dataset.rjs = 2;
            })
        }
    })
    
    retina();
}

function cta_fw_key_options(){

    if(!document.querySelectorAll('.design-key-carousel').length) return;
    let btn = document.querySelector('.design-key-button');

    btn.addEventListener('click', ()=>{ 
        let target = document.querySelector('.design-key-carousel .swiper-slide-active .target');
        target.click();
    });  
}